/**
 * Auth Mixin
 */
import { mapState, mapGetters } from 'vuex';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  components: {
    APIErrors: () => import('@/components/ui/APIErrors')
  },
  mixins: [GtmEventsMixin],
  data: () => ({
    errors: null,
    loading: false,
    loginSuccess: false,
    registerSuccess: false
  }),
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    }),
    ...mapState({
      showAuthModal: (state) => state.showAuthModal,
      showShareModal: (state) => state.showShareModal,
      selectedDeal: (state) => state.selectedDeal,
      isMobile: (state) => state.isMobile
    })
  },
  methods: {
    closeAuthModal() {
      this.$store.commit('setShowAuthModal', false);
    },
    async loginWithApple(idToken) {
      this.errors = null;
      this.loading = true;

      try {
        await this.$store.dispatch('auth/appleLogin', idToken);

        this.$toast.success(this.$i18n.t('welcome'));
        this.$gtm.push({ event: 'login_success' });

        this.loginSuccess = true;
        this.closeAuthModal();
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    async loginWithGoogle(idToken) {
      this.errors = null;
      this.loading = true;

      try {
        await this.$store.dispatch('auth/googleLogin', idToken);

        this.$toast.success(this.$i18n.t('welcome'));
        this.$gtm.push({ event: 'login_success' });

        this.loginSuccess = true;
        this.closeAuthModal();
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    async loginWithFacebook(accessToken) {
      this.errors = null;
      this.loading = true;

      try {
        await this.$store.dispatch('auth/facebookLogin', accessToken);

        this.$toast.success(this.$i18n.t('welcome'));
        this.$gtm.push({ event: 'login_success' });

        this.loginSuccess = true;
        this.closeAuthModal();
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    async login(credentials) {
      if (!credentials.email && !credentials.password) {
        return;
      }

      this.errors = null;
      this.loading = true;

      try {
        await this.$store.dispatch('auth/login', credentials);

        this.$toast.success(this.$i18n.t('welcome'));

        this.$gtm.push({ event: 'login_success' });

        this.loginSuccess = true;
        this.closeAuthModal();
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    async register(credentials) {
      if (
        !credentials.firstName &&
        !credentials.lastName &&
        !credentials.password
      ) {
        return;
      }

      this.loading = true;
      this.errors = null;

      const registerData = {
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        email: credentials.email,
        password: credentials.password,
        redirectUrl: `${window.location.origin}/activate`,
        newsletterSince: new Date(),
        privacyPolicySince: new Date()
      };

      try {
        await this.$store.dispatch('auth/register', registerData);

        this.$toast.success(this.$i18n.t('registerSuccess'));

        this.$gtm.push({ event: 'registration_success' });

        this.$store.commit('setShowAuthModal', false);
        this.registerSuccess = true;
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },

    async registerTravelAgent(credentials, registrationSource) {
      this.loading = true;
      this.errors = null;

      const registerData = {
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        email: credentials.email,
        password: credentials.password,
        redirectUrl: `${window.location.origin}/activate`,
        newsletterSince: new Date(),
        privacyPolicySince: new Date(),
        registrationSource
      };

      try {
        await this.$store.dispatch('auth/register', registerData);

        this.$toast.success(this.$i18n.t('registerSuccess'));

        this.$gtm.push({ event: 'travelagent_registration_success' });

        this.$store.commit('setShowAuthModal', false);
        this.registerSuccess = true;
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    },
    async registerWithTracking(credentials) {
      this.loading = true;
      this.errors = null;

      const registerData = {
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        email: credentials.email,
        password: credentials.password,
        redirectUrl: `${window.location.origin}/activate`,
        newsletterSince: new Date(),
        privacyPolicySince: new Date()
      };

      try {
        await this.$store.dispatch('auth/register', registerData);

        this.$toast.success(this.$i18n.t('registerSuccess'));

        this.$gtm.push({ event: 'registration_success' });

        this.$store.commit('setShowAuthModal', false);
        this.registerSuccess = true;
      } catch (e) {
        this.errors = e;
      } finally {
        this.loading = false;
      }
    }
  }
};
