<template>
  <div v-if="showTravelAgentButton">
    <HgBadge
      :class="showMovingBadge ? 'moving-container' : 'travel-badge-wrapper'"
      button-text="Sichern"
      :has-arrow="showArrow"
      @onClick="openModal()"
      @onClose="showModal = false"
    />

    <HgEmailRegister
      v-if="showModal"
      :errors="errors"
      @onClose="showModal = false"
      @onSubmit="submit"
      @pushGtmEvent="gtmEvent"
    >
      <template v-if="errors" #errors>
        <HgAlertBox :title="$t('errorHandling.errorBoxTitle')" type="error">
          <APIErrors :errors="errors" />
        </HgAlertBox>
      </template>
    </HgEmailRegister>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/AuthMixin';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  components: {
    APIErrors: () => import('@/components/ui/APIErrors'),
  },
  mixins: [AuthMixin, GtmEventsMixin],
  props: {
    showMovingBadge: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showModal: false,
    showTravelAgentButton: false,
    showArrow: true,
  }),

  watch: {
    showMovingBadge() {
      this.showArrow = !this.showMovingBadge;
    },
  },
  mounted() {
    this.showTravelAgentButton = this.timeWindow();
  },
  methods: {
    openModal() {
      this.showModal = true;
      this.$gtm.push({ event: 'travelagent_open' });
    },
    submit(credentials) {
      this.registerTravelAgent(credentials, 'TRAVEL_AGENT');
    },

    timeWindow() {
      const start = new Date('october 6, 2022 13:00:00');
      const end = new Date('october 8, 2022 23:59:59');
      const now = new Date();
      const result =
        end.getTime() > now.getTime() && start.getTime() < now.getTime();
      return result;
    },
    gtmEvent(eventInfo) {
      this.$gtm.push({ event: eventInfo });
    },
  },
};
</script>

<style lang="scss" scoped>
.moving-container {
  margin-bottom: 0;
  padding: 0 12px;
  position: fixed;
  bottom: 15px;
  z-index: 100;
  width: 100% !important;
  padding-right: 40px;
}

@media screen and (min-width: 768px) {
  .moving-container {
    padding: 0;
    left: 12px;
    max-width: 351px;
  }
  .travel-badge-wrapper {
    max-width: 351px;
  }
}
</style>
